<template>
    <el-dialog :title="$localization('web-client-form-my-invoice-address')" custom-class="edit-invoice-dialog" :visible.sync="visible" width="90%">
        <div class="dialog-custom-header">
            <h3>{{ $localization('web-client-form-my-invoice-address') }}</h3>
            <i class="icon-close" @click="$emit('update:visible', false)"></i>
        </div>
        <el-form>
            <el-form-item :label="$localization('web-client-form-country')">
                <el-input v-model="form.Country"></el-input>
                <span class="error">{{ $errorMessage("Country", validationErrors) }}</span>
            </el-form-item>

            <el-form-item :label="$localization('web-client-form-city')">
                <el-input v-model="form.City"></el-input>
                <span class="error">{{ $errorMessage("City", validationErrors) }}</span>
            </el-form-item>

            <el-form-item :label="$localization('web-client-form-district')">
                <el-input v-model="form.District"></el-input>
                <span class="error">{{ $errorMessage("District", validationErrors) }}</span>
            </el-form-item>

            <el-form-item :label="$localization('web-client-form-long-address')">
                <el-input v-model="form.Address"></el-input>
                <span class="error">{{ $errorMessage("Address", validationErrors) }}</span>
            </el-form-item>

            <el-form-item :label="$localization('web-client-form-postal-code')">
                <el-input v-model="form.ZipCode"></el-input>
                <span class="error">{{ $errorMessage("ZipCode", validationErrors) }}</span>
            </el-form-item>

            <el-form-item :label="$localization('web-client-form-phone')">
                <el-input v-model="form.PhoneNumber"></el-input>
                <span class="error">{{ $errorMessage("PhoneNumber", validationErrors) }}</span>
            </el-form-item>

            <el-form-item :label="$localization('web-client-form-identity-number-required')">
                <el-input v-model="form.IdentityNo" v-mask="mask.Identity"></el-input>
                <span class="error">{{ $errorMessage("IdentityNo", validationErrors) }}</span>
            </el-form-item>

            <el-button class="primary-btn save-button" @click="updateInvoiceSettings" :loading="loading" :disabled="loading">
                <i class="icon-refresh" v-if="!loading"></i>{{ $localization("web-client-form-save") }}
            </el-button>
        </el-form>
    </el-dialog>
</template>

<script>
export default {
    name: 'EditInvoiceDialog',
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            loading: false,
            validationErrors: [],
            form: {
                Country: '',
                City: '',
                District: '',
                Address: '',
                ZipCode: '',
                PhoneNumber: '',
                IdentityNo: ''
            },
            mask: {
                Identity: "###########"
            }
        }
    },
    computed: {
        user() {
            return this.$store.getters.getUser;
        }
    },
    watch: {
        visible(newVal) {
            if (newVal) {
                this.initForm();
            }
        }
    },
    methods: {
        initForm() {
            const invoiceAddress = this.user.InvoiceAddress;
            this.form = {
                Country: invoiceAddress.Country,
                City: invoiceAddress.City,
                District: invoiceAddress.District,
                Address: invoiceAddress.Address,
                ZipCode: invoiceAddress.ZipCode,
                PhoneNumber: invoiceAddress.PhoneNumber,
                IdentityNo: invoiceAddress.IdentityNo
            };
        },
        async updateInvoiceSettings() {
            this.loading = true;
            this.validationErrors = [];

            try {
                const res = await this.$client.post("/Account/UpdateUserInvoiceInfo", this.form);
                this.validationErrors = res.data.ValidationErrors;

                if (res.data.HasError) {
                    this.$message.error(res.data.Message);
                } else {
                    await this.$store.dispatch("getUser");
                    this.$message.success(res.data.Message);
                    this.$emit('update:visible', false);
                }
            } catch (error) {
                this.$message.error("Bir hata oluştu");
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>

<style lang="less">
.edit-invoice-dialog {
    .el-dialog__header {
        display: none !important;
    }
    .el-dialog__body {
        padding: 20px 20px;
        & > .dialog-custom-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        i {
            cursor: pointer;
        } 
    }
    }
    
}

.error {
    color: red;
    font-size: 12px;
}

.save-button {
    width: 100%;
    margin-top: 20px;
}
</style>