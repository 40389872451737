<template>
  <el-dialog :visible.sync="visible" custom-class="edit-profile-dialog" width="90%" :close-on-click-modal="false">
    <div class="dialog-custom-header">
      <h3>{{ $localization("web-client-personal-info") }}</h3>
      <i class="icon-close" @click="$emit('update:visible', false)"></i>
    </div>

    <div class="dialog">
      <div class="container">
        <el-form>
          <el-form-item :label="$localization('web-client-form-name')">
            <el-input class="login-input-email" v-model="userForm.FirstName" :disabled="user.IsMernisVerified"></el-input>
            <span class="error">{{ $errorMessage("FirstName", ValidationErrors) }}</span>
          </el-form-item>

          <el-form-item :label="$localization('web-client-form-surname')">
            <el-input class="login-input-email" v-model="userForm.LastName" :disabled="user.IsMernisVerified"></el-input>
            <span class="error">{{ $errorMessage("LastName", ValidationErrors) }}</span>
          </el-form-item>

          <el-form-item :label="$localization('web-register-nationality')">
            <el-select
              class="login-input-email"
              v-if="countryList.length > 0"
              v-model="userForm.CitizenshipId"
              style="width: 100%"
              :placeholder="$localization('web-register-nationality')"
              filterable
              clearable
              @change="
                userForm.City.ID = null;
                userForm.District.ID = null;
              "
              :disabled="user.IsMernisVerified">
              <el-option v-for="item in countryList" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
            </el-select>
            <span class="error">{{ $errorMessage("CitizenshipId", ValidationErrors) }}</span>
          </el-form-item>

          <template v-if="countryList.find((x) => x.ID == userForm.CitizenshipId) && countryList.find((x) => x.ID == userForm.CitizenshipId).Code != 'TR'">
            <el-form-item :label="$localization('web-client-form-passport-number')">
              <el-input class="login-input-email" maxlength="30" :placeholder="$localization('web-register-passport-number')" v-model="userForm.PassportNumber"></el-input>
              <span class="error">{{ $errorMessage("PassportNumber", ValidationErrors) }}</span>
            </el-form-item>
          </template>
          <template v-else>
            <el-form-item :label="$localization('web-client-form-identity-number')">
              <el-input class="login-input-email" maxlength="11" v-mask="mask.Identity" :placeholder="$localization('web-register-indentity-number')" v-model="userForm.IdentityNumber" :disabled="user.IsMernisVerified"></el-input>
              <span class="error">{{ $errorMessage("IdentityNumber", ValidationErrors) }}</span>
            </el-form-item>
          </template>

          <div v-if="countryList.find((x) => x.ID == userForm.CitizenshipId) && countryList.find((x) => x.ID == userForm.CitizenshipId).Code == 'TR'">
            <el-row :gutter="8">
              <el-col :span="12">
                <el-form-item :label="$localization('web-register-city')">
                  <el-select
                    class="login-input-email"
                    v-model="userForm.City.ID"
                    style="width: 100%"
                    :placeholder="$localization('web-register-city')"
                    filterable
                    clearable
                    @change="
                      userForm.District.ID = null;
                      getDistrictList(userForm.City.ID);
                    ">
                    <el-option v-for="item in cityList" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
                  </el-select>
                  <span class="error">{{ $errorMessage("CityId", ValidationErrors) }}</span>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item :label="$localization('web-register-district')">
                  <el-select class="login-input-email" v-model="userForm.District.ID" style="width: 100%" :placeholder="$localization('web-register-district')" filterable clearable :disabled="userForm.City.ID == null">
                    <el-option v-for="item in districtList" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
                  </el-select>
                  <span class="error">{{ $errorMessage("DistrictId", ValidationErrors) }}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </div>

          <el-form-item :label="$localization('web-client-form-birthdate')">
            <el-date-picker class="login-input-email" style="width: 100%" v-model="userForm.BirthDate" :disabled="user.IsMernisVerified" format="dd.MM.yyyy" type="date" :placeholder="$localization('web-client-form-please-select')" default-value="1980-01-01" prefix-icon="none"> </el-date-picker>
            <span class="error">{{ $errorMessage("Birthdate", ValidationErrors) }}</span>
          </el-form-item>

          <el-form-item :label="$localization('web-client-form-address-optional')">
            <div class="location">
              <el-input class="login-input-email" :placeholder="$localization('web-client-form-enter-address')" v-model="userForm.Address"></el-input>
              <el-button class="primary-btn" @click="findLocation" :loading="loading" :disabled="loading">{{ $localization("web-client-form-find-address") }} </el-button>
            </div>
            <span class="error">{{ $errorMessage("Address", ValidationErrors) }}</span>
          </el-form-item>

          <el-form-item :label="$localization('web-client-form-gender')">
            <el-select class="login-input-email" style="width: 100%" v-model="userForm.Gender" :placeholder="$localization('web-client-form-please-select')" v-if="genderEnums != null">
              <el-option v-for="(item, index) in genderEnums.Enums" :key="index" :label="item.DisplayName" :value="item.Key"> </el-option>
            </el-select>
            <span class="error">{{ $errorMessage("Gender", ValidationErrors) }}</span>
          </el-form-item>

          <el-form-item :label="$localization('web-client-form-institution')">
            <el-input class="login-input-email" :disabled="userForm.RegisterType == 1" v-model="userForm.Kurum"></el-input>
            <span class="error">{{ $errorMessage("Kurum", ValidationErrors) }}</span>
          </el-form-item>

          <el-form-item :label="$localization('web-client-form-email')" v-if="userForm.RegisterType == 1">
            <el-input class="login-input-email" :disabled="userForm.RegisterType == 1 && user.IsMernisVerified" v-model="userForm.Email"></el-input>
            <span class="error">{{ $errorMessage("Email", ValidationErrors) }}</span>
          </el-form-item>

          <el-form-item :label="$localization('web-client-form-phone')" v-if="userForm.RegisterType == 0">
            <div class="input-container-2" v-if="userForm.RegisterType == 0">
              <div class="login-input">
                <vue-phone-number-input
                  :disabled="Boolean(userForm.RegisterType == 0 && user.IsMernisVerified)"
                  v-model="userForm.PhoneNumber"
                  @update="phoneFormatter = $event"
                  :no-example="true"
                  :no-flags="true"
                  ref="phoneInput2"
                  :translations="{
                    countrySelectorLabel: $localization('web-login-country-code'),
                    countrySelectorError: $localization('web-login-select-country-code'),
                    phoneNumberLabel: $localization('web-login-phone-number'),
                    example: $localization('web-login-example'),
                  }"
                  size="md"
                  class="login-input-phone"
                  default-country-code="TR" />
              </div>
              <span class="error">{{ $errorMessage("PhoneNumber", ValidationErrors) }}</span>
            </div>
          </el-form-item>

          <div class="button-container-wrapper">
            <el-button class="danger-btn delete-account-button" @click="deleteAccount" type="danger">
              {{ $localization("web-client-delete-my-account") }}
            </el-button>
            <el-button class="primary-btn save-button" @click="updateProfileSettings()" :loading="loading" :disabled="loading">
              <i class="icon-refresh" v-if="!loading"></i>{{ $localization("web-client-form-save") }}
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import * as ActionNames from "@/store/action-names";
export default {
  name: "EditProfileDialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mask: {
        Identity: "###########",
        PhoneNumber: "+############",
      },
      phoneFormatter: null,
      loading: false,
      ValidationErrors: [],
      screenWidth: window.innerWidth,
      userForm: JSON.parse(JSON.stringify(this.$store.getters.getUser)),
    };
  },
  computed: {
    countryList() {
      return this.$store.getters.getCountryList;
    },
    cityList() {
      return this.$store.getters.getCityList;
    },
    districtList() {
      return this.$store.getters.getDistrictList;
    },
    genderEnums() {
      return this.$store.getters.getEnums.find((x) => x.Title == "Gender");
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    findLocation() {
      var self = this;
      navigator.geolocation.getCurrentPosition(function (item) {
        var url = "https://nominatim.openstreetmap.org/reverse?format=xml&lat=" + item.coords.latitude + "&lon=" + item.coords.longitude + "&zoom=18&addressdetails=1";
        window.jQuery.get(url, function (data) {
          self.userForm.Address = data.getElementsByTagName("province")[0].childNodes[0].nodeValue + " / " + data.getElementsByTagName("town")[0].childNodes[0].nodeValue;
          self.userForm.Latitude = item.coords.latitude.toString();
          self.userForm.Longitude = item.coords.longitude.toString();
        });
      });
    },

    async getDistrictList(id) {
      await this.$store.dispatch(ActionNames.GET_DISTRICT_LIST, id);
    },

    async updateProfileSettings() {
      this.loading = true;
      this.ValidationErrors = [];

      if (this.phoneFormatter != null) {
        if (this.phoneFormatter.isValid != false) {
          this.userForm.PhoneNumber = this.phoneFormatter.formatInternational.replaceAll(" ", "");
        } else {
          this.loading = false;
          this.$message.warning(this.$localization("web-client-phone-number-must-be-valid"));
          return;
        }
      } else {
        this.userForm.PhoneNumber = null;
        if (this.userForm.RegisterType == null || this.userForm.RegisterType == 0) {
          this.loading = false;
          this.$message.warning(this.$localization("web-client-phone-number-must-be-valid"));
          return;
        }
      }

      var tmpdate = this.userForm.BirthDate;
      if (this.user.IsMernisVerified == null || this.user.IsMernisVerified == false) {
        if (tmpdate != null) {
          tmpdate = this.$moment(tmpdate).utc(true);
        }
      }

      var updateObj = {
        FirstName: this.userForm.FirstName,
        LastName: this.userForm.LastName,
        CitizenshipId: this.userForm.CitizenshipId,
        IdentityNumber: this.userForm.IdentityNumber,
        PassportNumber: this.userForm.PassportNumber,
        CityId: this.userForm.City.ID,
        DistrictId: this.userForm.District.ID,
        PhoneNumber: this.userForm.PhoneNumber !== null ? (this.userForm.PhoneNumber.replaceAll("+" + this.phoneFormatter.countryCallingCode, "").trim().length === 0 ? null : this.userForm.PhoneNumber) : null,
        Email: this.userForm.Email !== null ? (this.userForm.Email.trim().length === 0 ? null : this.userForm.Email) : null,
        BirthDate: tmpdate,
        Gender: this.userForm.Gender,
        Address: this.userForm.Address,
        Latitude: this.userForm.Latitude,
        Longitude: this.userForm.Longitude,
        Weight: this.userForm.Weight,
        Height: this.userForm.Height,
        Kurum: this.userForm.Kurum
      };

      if (this.userForm.Weight == "") {
        updateObj.Weight = null;
      }
      if (this.userForm.Height == "") {
        updateObj.Height = null;
      }

      var res = await this.$client.post("/Account/UpdateUserPersonalInfo", updateObj);
      this.ValidationErrors = res.data.ValidationErrors;

      if (res.data.HasError) {
        this.$message.error(res.data.Message);
      } else {
        this.$message.success(res.data.Message);
        await this.$store.dispatch("getUser");
        this.$emit('update:visible', false);
      }
      this.loading = false;
    },

    async deleteAccount() {
      this.$confirm(this.$localization("web-client-are-you-sure-delete-account"), this.$localization("web-client-warning"), {
        confirmButtonText: this.$localization("web-client-yes"),
        cancelButtonText: this.$localization("web-client-no"),
        customClass: "alert-dialog",
        confirmButtonClass: "primary-btn btn-shadow",
        cancelButtonClass: "secondarary-btn",
        type: "warning",
      })
        .then(async () => {
          console.log("sil");
          var res = await this.$client.post("/Account/Delete", {});
          if (res.data.HasError) {
            this.$message.error(res.data.Message);
          } else {
            this.$message.success(res.data.Message);
            await this.$client.post("/Account/SignOut");
            localStorage.removeItem("token");
            this.$store.state.auth.user = null;
            this.$router.push({ name: "Login" });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$localization("web-client-process-cancelled"),
          });
        });
    },
  },
  mounted() {
    if (this.userForm.City == null) {
      this.userForm.City = { ID: null };
    }
    if (this.userForm.District == null) {
      this.userForm.District = { ID: null };
    }
    if (this.userForm.City != null) {
      this.getDistrictList(this.userForm.City.ID);
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.userForm = JSON.parse(JSON.stringify(this.$store.getters.getUser));
        if (this.userForm.City == null) {
          this.userForm.City = { ID: null };
        }
        if (this.userForm.District == null) {
          this.userForm.District = { ID: null };
        }
      }
    }
  }
};
</script>

<style lang="less">
.edit-profile-dialog {
  .el-dialog__header {
    display: none !important;
  }
  
  .el-dialog__body {
    padding: 20px 20px;
    .dialog {
        .container {
            width: 100%;
        }
    }
    .dialog-custom-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      
      i {
        cursor: pointer;
      }
    }
  }
}

.dialog {
  .container {
    .el-form-item {
      margin-bottom: 15px;
    }

    .location {
      display: flex;
      gap: 10px;
    }
  }
}

.error {
  color: red;
  font-size: 12px;
}

.button-container-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}


.danger-btn {
  background-color: #f56c6c;
  border-color: #f56c6c;
  color: white;
  
  &:hover {
    background-color: #f78989;
    border-color: #f78989;
  }
}
</style>

