<template>
  <el-dialog :visible.sync="visible" width="90%" class="children-settings-dialog" :close-on-click-modal="false">
    <div class="dialog">
      <div class="close" @click="$emit('update:visible', false)">
        <i class="icon-close"></i>
      </div>
      <div class="dialog-children">
        <div class="container">
          <div class="title2">{{ "Çocuk veya Yakın Ekle" }}</div>
          
          <div class="input-container">
            <div>{{ $localization("web-client-child-name") }}</div>
            <el-input class="login-input-email" v-model="form.FirstName" placeholder="..."></el-input>
            <span class="error">{{ $errorMessage("FirstName", validation) }}</span>
          </div>

          <div class="input-container">
            <div>{{ $localization("web-client-child-surname") }}</div>
            <el-input class="login-input-email" v-model="form.LastName" placeholder="..."></el-input>
            <span class="error">{{ $errorMessage("LastName", validation) }}</span>
          </div>

          <div class="input-container">
            <div>{{ $localization("web-register-nationality") }}</div>
            <el-select
              class="login-input-email"
              v-if="countryList.length > 0"
              v-model="form.CitizenshipId"
              style="width: 100%"
              :placeholder="$localization('web-register-nationality')"
              filterable
              clearable
              @clear="form.CitizenshipId = null">
              <el-option v-for="item in countryList" :key="item.ID" :label="item.Title" :value="item.ID"> </el-option>
            </el-select>
            <span class="error">{{ $errorMessage("CitizenshipId", validation) }}</span>
          </div>

          <div class="input-container">
            <div>{{ $localization("web-client-child-identity") }}</div>
            <el-input class="login-input-email tc" maxlength="11" v-mask="mask.Identity" :placeholder="$localization('web-client-child-identitynumber')" v-model="form.IdentityNumber"></el-input>
            <span class="error">{{ $errorMessage("IdentityNumber", validation) }}</span>
          </div>

          <div class="input input-container">
            <div class="text">{{ $localization("web-client-child-birthdate") }}</div>
            <el-date-picker class="login-input-email" style="width: 100%" v-model="form.BirthDate" format="dd.MM.yyyy" type="date" :placeholder="$localization('web-client-form-please-select')" prefix-icon="none"> </el-date-picker>
            <span class="error">{{ $errorMessage("BirthDate", validation) }}</span>
          </div>

          <div class="input-container">
            <div>{{ $localization("web-client-child-gender") }}</div>
            <el-select class="login-input-email" v-model="form.Gender" :placeholder="$localization('web-client-form-please-select')" v-if="genderEnums != null">
              <el-option v-for="(item, index) in genderEnums.Enums" :key="index" :label="item.DisplayName" :value="item.Key"> </el-option>
            </el-select>
            <span class="error">{{ $errorMessage("Gender", validation) }}</span>
          </div>

          <el-button class="primary-btn save-button" @click="save"><i class="icon-refresh"></i>{{ $localization("web-client-form-save") }}</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'AddUpdateChild',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      screenWidth: window.innerWidth,
      mask: {
        Identity: "###########"
      },
      form: {
        ParentId: null,
        FirstName: null,
        LastName: null,
        Gender: null,
        Title: null,
        ID: null,
        BirthDate: null,
        IdentityNumber: null,
        CitizenshipId: null
      }
    }
  },
  computed: {
    countryList() {
      return this.$store.getters.getCountryList;
    },
    genderEnums() {
      return this.$store.getters.getEnums.find((x) => x.Title == "Gender");
    },
    validation() {
      return this.$store.getters.getChildrenValidation;
    }
  },
  watch: {
    editData: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.form = { ...newVal };
        } else {
          this.resetForm();
        }
      }
    }
  },
  methods: {
    resetForm() {
      this.form = {
        ParentId: null,
        FirstName: null,
        LastName: null,
        Gender: null,
        Title: null,
        ID: null,
        BirthDate: null,
        IdentityNumber: null,
        CitizenshipId: null
      };
    },
    async save() {
      this.$store.commit("setChildrenValidation", []);
      
      if (this.form.IdentityNumber != null) {
        if (this.form.IdentityNumber.length != 11) {
          this.$message.error(this.$localization("web-client-identity-number-must-be-11-digits"));
          return;
        }
      } else {
        this.$message.error(this.$localization("web-client-identity-number-required"));
        return;
      }

      const response = await this.$store.dispatch("addUpdateChild", this.form);
      if (!response.data.HasError) {
        this.$message.success(response.data.Message);
        this.resetForm();
        this.$emit('update:visible', false);
        this.$emit('saved');
      }
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  }
}
</script>

<style lang="less" scoped>
.children-settings-dialog {
  :deep(.el-dialog) {
    border-radius: 10px;
    overflow: hidden;
    margin-top: 8vh !important;

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0;
    }
  }

  .dialog {
    position: relative;
    padding: 20px;
    
    .close {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 28px;
      height: 28px;
      padding: 3px;
      border-radius: 50%;
      background-color: #eaedf0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-close {
        width: 100%;
        height: 100%;
      }
    }

    .dialog-children {
        width: 100%;

      .container {
        .title2 {
          font-size: 24px;
          font-weight: 600;
          color: #44566c;
          margin-bottom: 30px;
        }

        .input-container {
          margin-bottom: 20px;

          & > div:first-child {
            font-size: 14px;
            color: #44566c;
            margin-bottom: 8px;
          }

          .error {
            color: #f56c6c;
            font-size: 12px;
            margin-top: 4px;
          }

          :deep(.el-input__inner),
          :deep(.el-select) {
            border-radius: 8px;
          }
        }

        .save-button {
          width: 100%;
          margin-top: 20px;
          height: 44px;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;

          i {
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
