<template>
  <div>
    <el-dialog :visible.sync="visible" :width="screenWidth > 991 ? '40%' : '90%'" class="card-settings-dialog" :close-on-click-modal="false">
      <div class="dialog">
        <div class="close" @click="$emit('update:visible', false)">
          <i class="icon-close"></i>
        </div>
        <div class="dialog-card">
          <div class="container">
            <div class="title2">{{ $localization("web-client-add-card") }}</div>
            
            <div class="input-container">
              <div>{{ $localization("web-interview-cardholder") }}</div>
              <el-input class="login-email-input" v-model="form.CardHolder" placeholder="..."></el-input>
              <span class="error">{{ $errorMessage("cardHolder", validationErrors) }}</span>
            </div>

            <div class="input-container">
              <div>{{ $localization("web-interview-cardno") }}</div>
              <el-input class="login-email-input" v-model="form.CardNO" v-mask="'#### #### #### ####'" placeholder="..."></el-input>
              <span class="error">{{ $errorMessage("cardNo", validationErrors) }}</span>
            </div>

            <div class="card-details">
              <div class="input-container">
                <div>{{ $localization("web-interview-expire-date") }}</div>
                <el-input class="login-email-input" v-model="form.ExpireDate" v-mask="'##/##'" placeholder="AA/YY"></el-input>
                <span class="error" v-if="validationErrors.some(x => x.Key == 'expireYear' || x.Key == 'expireMonth')">{{ $errorMessage("expireYear", validationErrors) }}</span>
              </div>

              <div class="input-container">
                <div>CVV</div>
                <el-input class="login-email-input" v-model="form.Cvc" v-mask="'###'" placeholder="..."></el-input>
                <span class="error">{{ $errorMessage("cvc", validationErrors) }}</span>
              </div>
            </div>

            <el-button class="primary-btn save-button" @click="saveCard()">
              <i class="icon-refresh"></i>{{ $localization("web-client-form-save") }}
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- Hidden div for 3D form submission -->
    <div id="hidden" style="display: none;"></div>
  </div>
</template>

<script>
export default {
  name: 'SavePaymentMethodDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      screenWidth: window.innerWidth,
      form: {
        CardNO: "",
        CardHolder: "",
        ExpireDate: "",
        Cvc: "",
      },
      validationErrors: [],
      threedHtml: ""
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.screenWidth = window.innerWidth;
    });
  },
  methods: {
    async saveCard() {
      // Expire date'i ay ve yıl olarak ayırma
      const [expireMonth, expireYear] = this.form.ExpireDate.split('/');
      
      const payload = {
        CardNo: this.form.CardNO.replaceAll(' ', ''),
        CardHolder: this.form.CardHolder,
        ExpireYear: expireYear,
        ExpireMonth: expireMonth,
        Cvc: this.form.Cvc,
        SaveCard: true,
        AppointmentRequestFrom: 0, 
        Permission: true
      };

      var res = await this.$client.post("/Account/SavePaymentMethodWeb", payload);
      if (res.data.HasError) {
        this.$message.error(res.data.Message);
        this.validationErrors = res.data.ValidationErrors;
      } else {
        if (res.data.Data.ThreeD) {
          this.threedHtml = res.data.Data.ThreedHtml;
          if (res.data.Data.goToThreedHtmlPage) {
            window.location.href = res.data.Data.ThreedHtml;
          } else {
            window.jQuery("#hidden").html(res.data.Data.ThreedHtml);
            window.jQuery("#hidden").find("form").submit();
          }
          this.$emit('update:visible', false);
        } else {
          this.$message.success(res.data.Message);
          await this.$store.dispatch("getUser");
          this.$emit('update:visible', false);
          // Form'u temizle
          this.form = {
            CardNO: "",
            CardHolder: "",
            ExpireDate: "",
            Cvc: "",
          };
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.card-settings-dialog {
  .el-dialog {
    min-width: 600px;
    border-radius: 1rem;
    overflow: hidden;

    @media screen and (max-width: 670px) {
      min-width: 340px;
    }

    .el-dialog__header {
      display: none;
    }

    .el-dialog__body {
      padding: 0;

      .dialog {
        min-width: 600px;
        position: relative;

        @media screen and (max-width: 670px) {
          min-width: 340px;
        }

        .dialog-card {
          padding: 14px;
          width: 100%;
          padding-bottom: 20px;

          .container {
            padding-left: 20px;
            padding-right: 20px;

            .card-details {
              display: flex;
              gap: 20px;

              .input-container {
                flex: 1;
              }
            }
          }
        }

        .close {
          position: absolute;
          right: 1rem;
          cursor: pointer !important;
          top: 1rem;
          width: 28px;
          height: 28px;
          padding: 3px;
          border-radius: 50%;
          background-color: #eaedf0;

          .icon-close {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
